import React, { useEffect, useState } from 'react';
import { Navbar, Container } from 'react-bootstrap';

const AppNavbar = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [
    `${process.env.PUBLIC_URL}/slide1.jpg`,
    `${process.env.PUBLIC_URL}/slide4.webp`,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [images.length]);

  const navbarStyle = {
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    color: 'white',
    padding: '10px 0',
  };

  const backgroundStyle = {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '60vh', // Full viewport height for responsiveness
    zIndex: 1,
    backgroundImage: `url(${images[currentImageIndex]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'background-image 1s ease-in-out', // Smooth transition
  };

  const overlayTextStyle = {
    position: 'absolute',
    top: '60%', // Center vertically
    left: '50%', // Center horizontally
    transform: 'translate(-50%, -50%)', // Adjust for center alignment
    color: 'white',
    textAlign: 'center',
    zIndex: 5, // Place above the background image
    fontSize: '2rem', // Adjust size for larger screens
    fontWeight: 'bold',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Add a semi-transparent background to the text
    padding: '10px 20px',
    borderRadius: '8px',
  };

  const logoStyle = {
    width: '80px', // Responsive size for mobile
    filter: 'invert(1)', // Inverts colors, making a black logo appear white
    opacity: '0.9',
    marginRight: '10px', // Adds space between logo and text
  };

  const brandTextStyle = {
    color: 'white',
    fontSize: '2rem', // Default size for larger screens
    fontWeight: 'bold',
    fontFamily: "'Poppins', sans-serif", // Attractive font
    textAlign: 'center', // Center the text
    flex: 1,
    padding: '10px', // Add padding to separate text from edges
    letterSpacing: '2px', // Add spacing between letters
    wordWrap: 'break-word', // Ensures text wraps in case it overflows
    maxWidth: '100%', // Ensures text doesn't overflow, even on smaller screens
    margin: '0 auto', // Centers the text within its container
  };

  return (
    <div style={{ position: 'relative', height: '60vh' }}>
      {/* Background changing dynamically */}
      <div style={backgroundStyle}></div>

      {/* Text overlay */}
      <div style={overlayTextStyle}>
        <h3>GET MORE PAY LESS</h3>
      </div>

      {/* Navbar with logo and text */}
      <Navbar style={navbarStyle} variant="dark" expand="lg">
        <Container
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Navbar.Brand
            href="/"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              flexDirection: 'row', // Align logo and text horizontally by default
              flexWrap: 'wrap', // Allow text to wrap on smaller screens
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/logo64deals.png`}
              style={logoStyle}
              alt="64Deals logo"
            />
            <span style={brandTextStyle} className="pt-serif-bold-italic">
              WELCOME TO 64DEALS
            </span>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </div>
  );
};

export default AppNavbar;
