import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppNavbar from './components/Navbar';
import HomePage from './components/HomePage';
import ItemDetail from './components/ItemDetail';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App = () => {
  const [deals, setDeals] = useState([]);
  const [filteredDeals, setFilteredDeals] = useState([]);

  useEffect(() => {
    fetch('https://fakestoreapi.com/products')
      .then(response => response.json())
      .then(data => {
        const dealsWithLinks = data.map(deal => ({
          ...deal,
          link: `https://fakestoreapi.com/products/${deal.id}`
        }));
        setDeals(dealsWithLinks);
        setFilteredDeals(dealsWithLinks);
      })
      .catch(error => console.error('Error fetching deals:', error));
  }, []);

  const handleSearch = (query) => {
    if (query) {
      const filtered = deals.filter(deal => deal.title.toLowerCase().includes(query.toLowerCase()));
      setFilteredDeals(filtered);
    } else {
      setFilteredDeals(deals);
    }
  };

  return (
    <Router>
      <div className="App" style={{ marginTop: 0 }}>
        <AppNavbar onSearch={handleSearch} />
        <Routes>
          <Route path="/" element={<HomePage deals={filteredDeals} onSearch={handleSearch} />} />
          <Route path="/item/:id" element={<ItemDetail deals={deals} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
