import React from 'react';
import { Row, Col } from 'react-bootstrap';
import DealItem from './DealItem';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';




const DealsList = ({ deals }) => (
  // <Row>
  //   {deals.map(deal => (
  //     <Col key={deal.id} sm={12} md={6} lg={3}>
  //       <DealItem deal={deal} />
  //     </Col>
  //   ))}
  // </Row>


  <Row>

<Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/seekingwisdom.jpg`} alt='Seeking Wisdom: From Darwin to Munger, 3rd Edition' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/Seeking-Wisdom-Darwin-Munger-3rd/dp/1578644283?&linkCode=ll1&tag=64deals-20&linkId=2d44ce04041f0fa4838bf9866afe56b3&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">Seeking Wisdom: From Darwin to Munger, 3rd Edition<br /><br /><br/><br /></Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $36.97</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px'}}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.5/5 <br/>
          {/* 7022042204 */}
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>

<Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/math.jpg`} alt='The Math of Life and Death: 7 Mathematical Principles That Shape Our Lives (Using Math in Everyday Life)' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/Math-Life-Death-Mathematical-Principles/dp/1982111887?crid=1F5SW8EMAE8PU&dib=eyJ2IjoiMSJ9.JisQ_I18P9kiU0SkOUI90rjxQrWSQqo_LGsiNn2ZS5O9OxLioFqsTleqk8om5XEPl9AOJpMM6i-1mrG3u1mWyBtBLGFKWO90HmK3d6Y2PNMH_k5hoY3A10eA5Mdw75f_dzUnaJxatdBIme166YfYVTYes94zh1L-4LpIZtE7ugs.TvDbgYnEp7qvs8EQQNkf_eVVPTiHAt31vIfNE-UDpOc&dib_tag=se&keywords=the+math+of+life+and+death+by+kit+yates&qid=1735419264&sprefix=the+math+of+%2Caps%2C168&sr=8-1&linkCode=ll1&tag=64deals-20&linkId=fdbce202eeac5f86284be64b227c0b75&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">The Math of Life and Death: 7 Mathematical Principles That Shape Our Lives (Using Math in Everyday Life)<br /><br/><br /></Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}


        <Card.Text className="custom-card-text">Price : $15.23</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px'}}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.4/5 <br/>
          {/* 7022042204 */}
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>


<Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/toy.jpg`} alt='Alloy Collectible Purple Pagani Huayra Dinastia Toy Vehicle Pull Back Die-Cast Car Model with Lights & Sounds' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/gp/aw/d/B0BN5T6SK4?smid=A34VDJ73J47QHV&psc=1&linkCode=ll1&tag=64deals-20&linkId=14ef7733832628acefc772d1c026cacb&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">Alloy Collectible Purple Pagani Huayra Dinastia Toy Vehicle Pull Back Die-Cast Car Model with Lights & Sounds<br /><br /><br /></Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $24.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px'}}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.5/5 <br/>
          {/* 7022042204 */}
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>

  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/party.jpg`} alt='Basketball Birthday Party Decorations Set Boys Basketball Happy Birthday Banner Hanging Swirls For Boys Men Basketball Game Sports' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/gp/aw/d/B0DLGV96GS?smid=A3OAHL8ANJKALD&th=1&linkCode=ll1&tag=64deals-20&linkId=4e241ea89d3c730a100ff7cb2fafc086&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">Basketball Birthday Party Decorations Set Boys Basketball Happy Birthday Banner Hanging Swirls For Boys Men Basketball Game Sports<br /><br /></Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $8.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px'}}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.5/5 <br/>
          {/* 7022042204 */}
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>

  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/lyst.jpg`} alt='Lyst 2PCS Basketball Table Cloth Plastic Table Cover Party Disposable Tablecloth Basketball Theme Party Decor for Kids and Adults Birthday Party Decorations Supplies' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/gp/aw/d/B0B8RTFB3Q?smid=A2CZ6IO29ZQK5&psc=1&linkCode=ll1&tag=64deals-20&linkId=0dba57f341836aeb7e48bc494f975efa&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">Lyst 2PCS Basketball Table Cloth Plastic Table Cover Party Disposable Tablecloth Basketball Theme Party Decor for Kids and Adults Birthday Party Decorations Supplies<br /></Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $6.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px'}}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
          </div>
          4.8/5 <br/>
          {/* 7022042204 */}
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>

  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/poster.jpg`} alt='Basketball Themed Birthday Party Decoration Basketball Happy Birthday Backdrop Photo Background Banner Poster for Basketball Party Decorations Party Supplies 70.8 x 47.2 Inch' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/gp/aw/d/B0BXSHF431?smid=A1TQIKHGTI5OTI&th=1&linkCode=ll1&tag=64deals-20&linkId=f5423b4b7819b18cb03005608ec18c91&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">Basketball Themed Birthday Party Decoration Basketball Happy Birthday Backdrop Photo Background Banner Poster for Basketball Party Decorations Party Supplies 70.8 x 47.2 Inch<br /></Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $9.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px'}}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.7/5 <br/>
          {/* 7022042204 */}
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>

  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/poen.jpg`} alt='Poen 48 Pcs Basketball Party Favors Basketball Themed Supplies Include 12 Drawstring Backpack 12 Mini Basketball 12 Keychain 12 Basketball Wax Thread' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/gp/aw/d/B0CKW3P9RV?smid=A3OT9KQ316W86T&th=1&linkCode=ll1&tag=64deals-20&linkId=9cf91d044366ce20b4269aa01429a339&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">Poen 48 Pcs Basketball Party Favors Basketball Themed Supplies Include 12 Drawstring Backpack 12 Mini Basketball 12 Keychain 12 Basketball Wax Thread Braided Bracelet<br /></Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $21.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px'}}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.7/5 <br/>
          {/* 7022042204 */}
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>

  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/phcover.jpg`} alt='ESR for iPhone 15 Pro Case, Compatible with MagSafe, Military-Grade Protection, Yellowing Resistant, Scratch-Resistant Back, Magnetic Phone Case' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/gp/aw/d/B0D3L7NV36?smid=A21IKPFG5X4LN8&th=1&linkCode=ll1&tag=64deals-20&linkId=ab9bbcc07bedd3d09a0b0e658e3b3dba&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">ESR for iPhone 15 Pro Case, Compatible with MagSafe, Military-Grade Protection, Yellowing Resistant, Scratch-Resistant Back, Magnetic Phone Case</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $14.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px'}}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.6/5 <br/>
          {/* 7022042204 */}
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>


<Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/watch.jpg`} alt='Health Fitness Tracker with 24/7 Heart Rate, Blood Oxygen, Blood Pressure, Sleep Tracker, 5ATM Waterproof Activity Trackers with Step Tracker, Pedometer (S & L Bands Included)' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/Amzhero-Pressure-Waterproof-Activity-Pedometer/dp/B0D3BM1PXJ?sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9waG9uZV9zZWFyY2hfYXRm&th=1&linkCode=ll1&tag=64deals-20&linkId=66a18dfca040584b3df1cd60ef31cdec&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">Health Fitness Tracker with 24/7 Heart Rate, Blood Oxygen, Blood Pressure, Sleep Tracker, 5ATM Waterproof Activity Trackers with Step Tracker, Pedometer...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $49.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          3.7/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>

<Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/shoes.jpg`} alt='Brooks Men’s Beast GTS 23 Supportive Running Shoe' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/gp/aw/d/B0BJH3MVYV?smid=A29S2OAT50BK3U&psc=1&linkCode=ll1&tag=64deals-20&linkId=a0257b91fd4a09977131ddb3d5dec938&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">Brooks Men’s Beast GTS 23 Supportive Running Shoe <br /><br /><br /> </Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $109.75</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px'}}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.7/5 <br/>
          {/* 7022042204 */}
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>


    <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/acer.jpg`} alt='
Acer Swift Go Intel Evo Thin & Light Premium Laptop 14" 1920x1200 100% sRGB Touch Screen Display Intel Core i7-1355U Intel Iris Xe 16GB LPDDR5 512GB...' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/Laptop-1920x1200-Display-i7-1355U-SFG14-71T-72QV/dp/B0BTQWR77M?pd_rd_w=80a7X&content-id=amzn1.sym.40ea2bfe-12f4-4a4d-9134-758b59958750&pf_rd_p=40ea2bfe-12f4-4a4d-9134-758b59958750&pf_rd_r=ESN0EG4P07EDMTDXHE35&pd_rd_wg=E9t22&pd_rd_r=58b8e0e8-2146-448d-a18a-7e1537374b6e&pd_rd_i=B0BTQWR77M&th=1&linkCode=ll1&tag=64deals-20&linkId=c635d00553fc70ba859db2309885d2b5&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">Acer Swift Go Intel Evo Thin & Light Premium Laptop 14" 1920x1200 100% sRGB Touch Screen Display Intel Core i7-1355U Intel Iris Xe 16GB LPDDR5 512GB...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $599.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.3/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/hp2.jpg`} alt='
HP Newest 15.6" Multitasking Slim Laptop, 16GB RAM, 256GB Storage (128GB SSD Onboard and 128GB External Drive), Free Office 365 1-Year, Intel 4-core...' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/HP-Multitasking-Storage-External-Processor/dp/B0DB9CHCJG?pd_rd_w=80a7X&content-id=amzn1.sym.40ea2bfe-12f4-4a4d-9134-758b59958750&pf_rd_p=40ea2bfe-12f4-4a4d-9134-758b59958750&pf_rd_r=ESN0EG4P07EDMTDXHE35&pd_rd_wg=E9t22&pd_rd_r=58b8e0e8-2146-448d-a18a-7e1537374b6e&pd_rd_i=B0DB9CHCJG&th=1&linkCode=ll1&tag=64deals-20&linkId=d197c77287b26c0699dc1bef38f02961&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">HP Newest 15.6" Multitasking Slim Laptop, 16GB RAM, 256GB Storage (128GB SSD Onboard and 128GB External Drive), Free Office 365 1-Year, Intel 4-core...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $245.88</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.3/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/apple.jpg`} alt="Apple Watch SE (2nd Gen) [GPS 40mm] Smartwatch with Starlight Aluminum Case with Starlight Sport Band S/M. Fitness & Sleep Tracker, Crash Detection,..." />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/Apple-Cellular-Smartwatch-Starlight-Detection/dp/B0CHXCHR51?crid=37G40HSFQFR08&dib=eyJ2IjoiMSJ9.34WE9_i5Fbtuw9RdyVouCuL3fQ7Bq7tcEEWTOXMZsiyZOTWnFEGL4oRGSk4hmxtgMztNcgsBbl4xzKST_OPclbk985bxksq-nZRtKk13Wsh7fPqHoIpQdvTjEXws-vp_C-ADeva98fAgfgSwr2OwcKvh0ip-KE3TTrvs5Z1ab7EIsXS7k4xVYWMXj-3w5uUCdr8v7xVSDMCU4REg4cVPjSF4BLOgb8pOnElzODd67sA.sxbYok9to2M0HGAEK-3OZLqk63XY8xiuo92inp7zDk4&dib_tag=se&keywords=Apple%2BWatch%2BSE%2B%282nd%2BGen%29%2B%5BGPS%2B40mm%5D%2BSmartwatch%2Bwith%2BStarlight%2BAluminum%2BCase%2Bwith%2BStarlight%2BSport%2BBand%2BS%2FM.%2BFitness%2B%26%2BSleep%2BTracker%2C%2BCrash%2BDetection&qid=1735574753&sprefix=apple%2Bwatch%2Bse%2B2nd%2Bgen%2Bgps%2B40mm%2Bsmartwatch%2Bwith%2Bstarlight%2Baluminum%2Bcase%2Bwith%2Bstarlight%2Bsport%2Bband%2Bs%2Fm.%2Bfitness%2B%26%2Bsleep%2Btracker%2C%2Bcrash%2Bdetection%2Caps%2C652&sr=8-1&th=1&linkCode=ll1&tag=64deals-20&linkId=f173e8d478d1cd95f3563de4e3526227&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">Apple Watch SE (2nd Gen) [GPS 40mm] Smartwatch with Starlight Aluminum Case with Starlight Sport Band S/M. Fitness & Sleep Tracker, Crash Detection,...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $197.01</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.6/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/apple1.jpg`} alt="
Apple Watch Series 9 [GPS 41mm] Smartwatch with Pink Aluminum Case with Light Pink Sport Band S/M. Fitness Tracker, ECG Apps, Always-On Retina Display,..." />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/dp/B0CSV9Y331?th=1&linkCode=ll1&tag=64deals-20&linkId=ac10af48d80aaf7824fe5a54571047a5&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">Apple Watch Series 9 [GPS 41mm] Smartwatch with Pink Aluminum Case with Light Pink Sport Band S/M. Fitness Tracker, ECG Apps, Always-On Retina Display,...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $379.93</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.7/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/asus1.jpg`} alt="ASUS ROG Strix G16 (2024) Gaming Laptop, 16” 16:10 FHD 165Hz Display, NVIDIA® GeForce RTX™ 4060, Intel Core i7-13650HX, 16GB DDR5, 1TB PCIe Gen4 SSD, Wi-Fi..." />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/ASUS-ROG-Strix-Gaming-Laptop/dp/B0CRDCXRK2?_encoding=UTF8&pd_rd_w=dLcZU&content-id=amzn1.sym.7ca82549-745a-43ca-900c-9fc4fd553939&pf_rd_p=7ca82549-745a-43ca-900c-9fc4fd553939&pf_rd_r=JCSJ2YR7T3JWVHN0HGJK&pd_rd_wg=ksgUT&pd_rd_r=ce1308fc-9e2e-477f-b64a-67b44b566c72&th=1&linkCode=ll1&tag=64deals-20&linkId=80ac9f29fedb2dd255b87763446a5c02&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">ASUS ROG Strix G16 (2024) Gaming Laptop, 16” 16:10 FHD 165Hz Display, NVIDIA® GeForce RTX™ 4060, Intel Core i7-13650HX, 16GB DDR5, 1TB PCIe Gen4 SSD, Wi-Fi...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $1260.00</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.3/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/stanley.jpg`} alt="Stanley Quencher H2.0 FlowState Stainless Steel Vacuum Insulated Tumbler with Lid and Straw for Water, Iced Tea or Coffee" />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/Quencher-FlowState-Stainless-Insulated-Smoothie/dp/B0CP9YB3Q4?pd_rd_w=LXHnw&content-id=amzn1.sym.7379aab7-0dd8-4729-b0b5-2074f1cb413d&pf_rd_p=7379aab7-0dd8-4729-b0b5-2074f1cb413d&pf_rd_r=TS87ATVE3W82KMSAFA0Y&pd_rd_wg=wdQcA&pd_rd_r=557617fe-1755-40f5-8f41-90e1b34a39ca&pd_rd_i=B0CP9YB3Q4&th=1&linkCode=ll1&tag=64deals-20&linkId=668c840158806a9c0240f1c45af777f0&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">Stanley Quencher H2.0 FlowState Stainless Steel Vacuum Insulated Tumbler with Lid and Straw for Water, Iced Tea or Coffee</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $35.00</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /> 
          </div>
          4.7/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/owala.jpg`} alt="Owala FreeSip Insulated Stainless Steel Water Bottle with Straw for Sports and Travel, BPA-Free, 24-oz, Very, Very Dark" />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/Owala-Insulated-Stainless-Steel-Push-Button-24-Ounce/dp/B085DTZQNZ?pd_rd_w=UuGIs&content-id=amzn1.sym.7379aab7-0dd8-4729-b0b5-2074f1cb413d&pf_rd_p=7379aab7-0dd8-4729-b0b5-2074f1cb413d&pf_rd_r=FE4GJ3CM58YBN7NDFDVT&pd_rd_wg=vNQR1&pd_rd_r=17e52884-c8af-415c-947e-391a886e364f&pd_rd_i=B085DTZQNZ&th=1&linkCode=ll1&tag=64deals-20&linkId=630bcf3ac373a53423550a18d84dfd08&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">Owala FreeSip Insulated Stainless Steel Water Bottle with Straw for Sports and Travel, BPA-Free, 24-oz, Very, Very Dark</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $22.07</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /> 
          </div>
          4.7/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/zevo.jpg`} alt="Zevo Flying Insect Trap for Indoors: Light Trap Captures Fruit Flies, Gnats and Houseflies, Starter Kit (1 Plug-in Base + 1 Cartridge)" />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/Electric-Flying-Insect-Trap-Starter/dp/B07T869RNY?pd_rd_w=UuGIs&content-id=amzn1.sym.7379aab7-0dd8-4729-b0b5-2074f1cb413d&pf_rd_p=7379aab7-0dd8-4729-b0b5-2074f1cb413d&pf_rd_r=FE4GJ3CM58YBN7NDFDVT&pd_rd_wg=vNQR1&pd_rd_r=17e52884-c8af-415c-947e-391a886e364f&pd_rd_i=B07T869RNY&th=1&linkCode=ll1&tag=64deals-20&linkId=b928f2165555238e8031c428aee0ad5f&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">Zevo Flying Insect Trap for Indoors: Light Trap Captures Fruit Flies, Gnats and Houseflies, Starter Kit (1 Plug-in Base + 1 Cartridge)</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $19.49</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /> 
          </div>
          4.3/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/julep.jpg`} alt="Julep Eyeshadow 101 Crème to Powder Waterproof Eyeshadow Stick, Champagne Shimmer" />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/Julep-Eyeshadow-Hypoallergenic-Waterproof-Available/dp/B01LW1983J?pd_rd_w=j4q2p&content-id=amzn1.sym.7379aab7-0dd8-4729-b0b5-2074f1cb413d&pf_rd_p=7379aab7-0dd8-4729-b0b5-2074f1cb413d&pf_rd_r=FE4GJ3CM58YBN7NDFDVT&pd_rd_wg=vNQR1&pd_rd_r=17e52884-c8af-415c-947e-391a886e364f&pd_rd_i=B01LW1983J&psc=1&linkCode=ll1&tag=64deals-20&linkId=6427fed10948624e00ec851b601cda28&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">Julep Eyeshadow 101 Crème to Powder Waterproof Eyeshadow Stick, Champagne Shimmer</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $18.00</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar />
          </div>
          4.2/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/lenovo1.jpg`} alt='lenovo 15.6" Touchscreen 20GB RAM 1TB SSD IdeaPad 3i Laptop, Windows 11 Pro, Intel Core i5-1135G7 4-Core Processor, FHD 1920x1080 Display, SD Card Reader, HDMI, USB-C, Arctic Grey' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/lenovo-Touchscreen-i5-1135G7-Processor-1920x1080/dp/B0CHN3YKW8?pd_rd_w=W3X67&content-id=amzn1.sym.46e2be74-be72-4d3f-86e1-1de279690c4e&pf_rd_p=46e2be74-be72-4d3f-86e1-1de279690c4e&pf_rd_r=8771WW2ASWGA7Q9RNC3S&pd_rd_wg=BZep6&pd_rd_r=20ee9bf0-e196-41cb-81b8-d8a990460df8&pd_rd_i=B0CHN3YKW8&th=1&linkCode=ll1&tag=64deals-20&linkId=3e7424e694b11a8e25dc417c97c411cd&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">lenovo 15.6" Touchscreen 20GB RAM 1TB SSD IdeaPad 3i Laptop, Windows 11 Pro, Intel Core i5-1135G7 4-Core Processor, FHD 1920x1080 Display, SD Card Reader, HDMI, USB-C, Arctic Grey</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $579.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /> 
          </div>
          4.5/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/lenovo2.jpg`} alt="lenovo 15.6 Touchscreen 16GB RAM 1TB SSD IdeaPad 3i Laptop, Windows 11 Pro, Intel Core i5-1155G7 4-Core Processor, FHD 1920x1080 Display, Dolby Audio, Media..." />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/lenovo-Touchscreen-i5-1155G7-Processor-1920x1080/dp/B0CJPBVWWG?pd_rd_w=W3X67&content-id=amzn1.sym.46e2be74-be72-4d3f-86e1-1de279690c4e&pf_rd_p=46e2be74-be72-4d3f-86e1-1de279690c4e&pf_rd_r=8771WW2ASWGA7Q9RNC3S&pd_rd_wg=BZep6&pd_rd_r=20ee9bf0-e196-41cb-81b8-d8a990460df8&pd_rd_i=B0CJPBVWWG&th=1&linkCode=ll1&tag=64deals-20&linkId=180c019fbd4dcef08ef3cf697645cde8&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">lenovo 15.6 Touchscreen 16GB RAM 1TB SSD IdeaPad 3i Laptop, Windows 11 Pro, Intel Core i5-1155G7 4-Core Processor, FHD 1920x1080 Display, Dolby Audio, Media...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $679.00</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /> 
          </div>
          4.4/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/hp1.jpg`} alt='HP Notebook Laptop, 15.6" HD Touchscreen, Intel Core i3-1115G4 Processor, 32GB RAM, 1TB PCIe SSD, Webcam, Type-C, HDMI, SD Card Reader, Wi-Fi, Windows...' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/HP-Notebook-Touchscreen-i3-1115G4-Processor/dp/B0BZ8X9HGT?pd_rd_w=NbI7U&content-id=amzn1.sym.c33ad739-91a9-476e-b522-fd0cf7ffda5c&pf_rd_p=c33ad739-91a9-476e-b522-fd0cf7ffda5c&pf_rd_r=8771WW2ASWGA7Q9RNC3S&pd_rd_wg=BZep6&pd_rd_r=20ee9bf0-e196-41cb-81b8-d8a990460df8&pd_rd_i=B0BZ8X9HGT&th=1&linkCode=ll1&tag=64deals-20&linkId=d85ceeb1efa25cfc2c3552394e54a03c&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">HP Notebook Laptop, 15.6" HD Touchscreen, Intel Core i3-1115G4 Processor, 32GB RAM, 1TB PCIe SSD, Webcam, Type-C, HDMI, SD Card Reader, Wi-Fi, Windows...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $416.71</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar />
          </div>
          4.2/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/asus.jpg`} alt="ASUS Vivobook 16” WUXGA Laptop, Intel Core i5-1235U, Intel Iris Xe Graphics, 16GB Memory, 512GB SSD, Cool Silver, F1605ZA-AS56" />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/ASUS-Vivobook-i5-1235U-Graphics-F1605ZA-AS56/dp/B0CWJ642YM?crid=1P16Q9WHT2B0R&dib=eyJ2IjoiMSJ9.bzKcFrNd8Nf4GM4749Nj2_2f3t7hVsyLedjtiYWuHS7hLXJwRzVPt2njOWn1et7tyJPT_ntmkn3mCr9X-k_CajagXvvwbML__dlkMfLk9y8RiwsWGizaE4efHNPI8sAA442MRhFlZwd4_XH9CRVFXG3a48TSXkVuIYQv-hMotwtKXc6Zq3Xsg9Ruz3TZRDpKBAnJGJs5UmoPzV_LHwa0N28LxBIuCqDiNDC7RrjFln0J5aiOM1cT0YBU4od3nrp8WVXd67fOQ4rz2jGTzvv5RpC6xqJCRBHsqA8h6MVOp4OrDTR44HCPQSD9Vbq_BmTkIDo53O2E3qTg2qvBIphgKYNv2M4A_zhTbwREc1lEKzU.ofeSuh178IBLCRDvuOXmkbKydxWlYkI4Dh3GykLU4dw&dib_tag=se&keywords=asus%2Bvivobook%2B16&qid=1735063034&s=electronics&sprefix=asus%2Bvivo%2Celectronics%2C556&sr=1-3&th=1&linkCode=ll1&tag=64deals-20&linkId=958e8db21d2d18cc3c15235a697f0c15&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">ASUS Vivobook 16” WUXGA Laptop, Intel Core i5-1235U, Intel Iris Xe Graphics, 16GB Memory, 512GB SSD, Cool Silver, F1605ZA-AS56</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $516.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStarHalfAlt /> 
          </div>
          3.7/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>

  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/ssdsamsung.jpg`} alt="SAMSUNG T7 Portable SSD, 4TB External Solid State Drive, Speeds Up to 1,050MB/s, USB 3.2 Gen 2, Reliable Storage for Gaming, Students, Professionals,..." />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://www.amazon.com/SAMSUNG-Portable-Professionals-MU-PC4T0T-AM/dp/B0CX9BV341?th=1&linkCode=ll1&tag=64deals-20&linkId=9a6b8aeee4d6a629d26436d4fca88050&language=en_US&ref_=as_li_ss_tl">
        <Card.Title className="custom-card-text">
        SAMSUNG T7 Portable SSD, 4TB External Solid State Drive, Speeds Up to 1,050MB/s, USB 3.2 Gen 2, Reliable Storage for Gaming, Students, Professionals,...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $279.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
          </div>
          4.8/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>


  </Row>
);

export default DealsList;
