import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import Rating from 'react-rating-stars-component';

const ItemDetail = ({ deals }) => {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [relatedItems, setRelatedItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchItem = async () => {
      const response = await fetch(`https://fakestoreapi.com/products/${id}`);
      const data = await response.json();
      setItem(data);
      setLoading(false);
    };

    fetchItem();
  }, [id]);

  useEffect(() => {
    const fetchRelatedItems = async () => {
      const response = await fetch(`https://fakestoreapi.com/products`);
      const data = await response.json();
      const related = data.filter((deal) => deal.category === item?.category && deal.id !== item.id);
      setRelatedItems(related);
    };

    if (item) {
      fetchRelatedItems();
    }
  }, [item]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) return <div className="d-flex justify-content-center my-4"><Spinner animation="border" /></div>;

  return (
    <Container className="my-4">
      <Row>
        <Col md={8}>
          <Card style={{ alignItems: 'center' }} >
            <Card.Img style={{ height: '350px', width: '200px' }} variant="top" src={item.image} alt={item.title} />
            <Card.Body style={{ alignItems: 'center' }}>
              <Card.Title>{item.title}</Card.Title>
              <Card.Text>{item.description}</Card.Text>
              {/* <Card.Text><strong>Price:</strong> ${item.price}</Card.Text> */}
              <Card.Text><strong>Price:</strong></Card.Text>
              <div className="mb-3">
                <strong>Rating:</strong> <Rating value={item.rating.rate} edit={false} size={24} />
                <span className="ms-2">({item.rating.count} reviews)</span>
              </div>
              <Button variant="primary" href={item.link} target="_blank">Buy Now</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <h4>Related Products</h4>
          {relatedItems.map((relatedItem) => (
            <Card key={relatedItem.id} className="mb-2">
              <Link to={`/item/${relatedItem.id}`}>
                <Card.Img style={{ height: '200px' }} variant="top" src={relatedItem.image} alt={relatedItem.title} />
                <Card.Body>
                  <Card.Title>{relatedItem.title}</Card.Title>
                  {/* <Card.Text>${relatedItem.price}</Card.Text> */}
                  <Card.Text>Price :</Card.Text>
                  <div>
                    <Rating value={relatedItem.rating.rate} edit={false} size={20} />
                    <span className="ms-2">({relatedItem.rating.count} reviews)</span>
                  </div>
                </Card.Body>
              </Link>
            </Card>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default ItemDetail;
