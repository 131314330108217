import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import DealsList from './DealsList';

const HomePage = ({ deals, onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(searchQuery);
  };

  return (
    <>
      {/* <div className="hero-image">
        <div className="hero-text">
          <Form inline="true" onSubmit={handleSearch}>
            <Form.Control
              type="text"
              placeholder="Search for deals..."
              className="mr-sm-2"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button type="submit" variant="primary">Search</Button>
          </Form>
        </div>
      </div> */}
      <div className="deals-container">
        <DealsList deals={deals} />
      </div>
    </>
  );
};

export default HomePage;
